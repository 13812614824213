<template>
  <main class="content category-page">
    <div v-if="selectedCategory && shop" class="container">

      <div
        v-if="selectedCategory.banner"
        class="category-page-banner"
      >
        <img
          :src="`${staticHost}/${selectedCategory.banner}`"
          :alt="selectedCategory.name"
        />
      </div>

      <router-link
        :to="{ name: 'home' }"
        class="back-link"
      >
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </router-link>

      <div v-if="false" class="sorting">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div>
              <div class="form-group mb-lg-0 with-icon category-search">
                <label for="category-search-input" >
                  <input
                    @keyup.enter="applyFilter()"
                    v-model="filter.q"
                    id="category-search-input"
                    class="input-big"
                    type="text"
                    :placeholder="$t('cs.front.search')"
                  />
                </label>

                <button @click="applyFilter()" type="submit" style="padding-left: 1rem;">
                  <span class="icon icon-search"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-1">

          </div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-4">
                <label for="color-select">
                  <select v-model="filter.colorId" id="color-select">
                    <option value="">{{ $t('cs.front.color') }}</option>
                    <option
                      v-for="color in colors"
                      :key="color.id"
                      :value="color.id"
                    >
                        {{ color.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-md-4">
                <label for="gender-select">
                  <select v-model="filter.gender" id="gender-select">
                    <option value="">{{ $t('cs.front.gender') }}</option>
                    <option
                      v-for="gender in genders"
                      :key="gender.id"
                      :value="gender.id">
                      {{ gender.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <aside class="col-md-4 col-lg-3">
          <div v-if="(!isMobile || showFilters)" class="sidebar">
            <div class="sidebar-header">
              <div>
                <span class="icon icon-filter mr-3"></span>
                {{ $t('cs.front.filter') }}
              </div>
              <button @click="showFilters = false" class="filter-toggle" type="button">
                <span class="icon icon-close"></span>
              </button>
            </div>
            <div class="sidebar-body">
              <p class="title">{{ $t('cs.front.sortiment') }}</p>
              <ul class="sortiment-list">
                <li
                  v-for="category in categoriesList"
                  :key="category.id"
                >
                  <template
                    v-if="category.status > 0 && (categoryType === 'cs' || category.discount)"
                  >
                    <router-link
                      v-if="categoryType === 'cs'"
                      :to="{ name: 'category', params: { categoryId: category.slug }}"
                    >
                      {{ category.name }}
                    </router-link>
                    <router-link
                      v-if="categoryType === 'eo'"
                      :to="{ name: 'eo-products', params: { eoCategoryId: category.id }}"
                    >
                      {{ category.name }}
                    </router-link>
                    <ul
                      v-if="category.subcategories &&
                    category.subcategories.length > 0"
                      style="padding-top: 1rem;"
                    >
                      <li v-for="category2 in category.subcategories" :key="category2.id">
                        <router-link
                          v-if="categoryType === 'cs'"
                          :to="{ name: 'category', params: { categoryId: category2.slug }}"
                        >
                          {{ category2.name }}
                        </router-link>
                        <router-link
                          v-if="categoryType === 'eo'"
                          :to="{ name: 'eo-products', params: { eoCategoryId: category2.id }}"
                        >
                          {{ category2.name }}
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </li>
              </ul>
              <hr>

              <div>
                <template v-if="selectedCategory.sizes"></template>
                <p class="title">{{ $t('cs.front.size') }}:</p>
                  <div
                    v-for="size in selectedCategory.sizes"
                    :key="size.id"
                    class="size"
                    style="width: 2.8rem; float: left;"
                  >
                    <input
                      v-model="filter.size_id"
                      :id="`s${size.id}`"
                      :value="size.id"
                      type="radio"
                    >

                    <label
                      :title="size.name"
                      :for="`s${size.id}`"
                    >
                      {{ size.name }}
                    </label>
                  </div>

                <br /><br />
                <hr style="clear: both;">

                <p class="title">{{ $t('cs.front.color') }}:</p>
                  <div class="colors">
                    <div
                      v-for="color in colors"
                      :key="color.id"
                      class="color"
                    >
                      <input
                        v-model="filter.colorId"
                        :id="`c${color.id}`"
                        :value="color.id"
                        type="radio"
                      >

                      <label
                        :title="color.name"
                        :for="`c${color.id}`"
                        :style="color.css_style"
                      ></label>
                    </div>
                  </div>
                <hr>

                <div class=" d-md-none">
                  <p class="title">{{ $t('cs.front.gender') }}:</p>
                  <div class="genders">
                    <div
                      v-for="gender in genders"
                      :key="gender.id"
                      class="gender"
                      style="float: left; padding: 1rem;"
                    >
                      <input
                        v-model="filter.gender"
                        :id="`c${gender.id}`"
                        :value="gender.id"
                        type="radio"
                      >

                      <label
                        :title="gender.name"
                        :for="`c${gender.id}`"
                      >{{ gender.name }}</label>
                    </div>
                    <div style="clear: both;"></div>
                    <hr />
                  </div>
                </div>
              </div>

              <p class="title">{{ $t('cs.front.price') }}</p>

              <div class="range-slider">
                <vue-slider v-model="filter.prices" v-bind="priceSliderOptions"></vue-slider>

                <div class="values">
                  <div>
                    <input
                      @keyup.enter="applyFilter"
                      v-model="filter.prices[0]"
                      class="input-small" id="range-min" type="number"
                    />
                    {{ this.shop.currency }}
                  </div>
                  <div>
                    <input
                      @keyup.enter="applyFilter"
                      v-model="filter.prices[1]"
                      class="input-small"
                      id="range-max"
                      type="number"
                    />
                    {{ this.shop.currency }}
                  </div>
                </div>
              </div>

              <hr>

              <button @click="applyFilter()"
                class="btn btn-black mb-3 d-none d-md-block" type="button">
                {{ $t('cs.front.filter_apply') }}
              </button>
              <button @click="clearFilter()"
                class="btn" type="button">
                {{ $t('cs.front.clear') }}
                <span class="icon icon-close d-none d-md-block"></span>
              </button>
            </div>
          </div>
        </aside>
        <main v-if="productsList.data" class="col-md-8 col-lg-9">
          <h1 class="h2">{{ selectedCategory.seo_h1 ?? selectedCategory.name }}</h1>
          <button @click="showFilters = true" class="btn filter-toggle" type="button">
            <i class="icon icon-filter mr-2"></i>
            {{ $t('cs.front.filter') }} ({{ productsList.data.length }})
            <i class="icon icon-arrow-follow"></i>
          </button>
          <div class="category-list">
            <router-link
              :to="productUrl(product)"
              v-for="product in productsList.data"
              :key="product.id"
              class="category-item"
            >
              <div class="img">
                <img :src="product.image" :alt="product.number">
              </div>
              <div class="desc">
                <div class="label" v-if="product.discount && product.discount !== '0%'">
                  {{ product.discount }}
                </div>
                <div class="name">{{ product.name }}</div>
                <div class="price">
                  <template v-if="product.display_rrp">
                    <s>
                      <small>{{ $filters.priceFormat(product.price_rrp, product.currency) }}</small>
                    </s>
                    &nbsp;
                    <template v-if="product.price_prefix">
                      {{ $t('cs.front.at') }} &nbsp;
                    </template>

                    <strong style="color: red;">
                      {{ $filters.priceFormat(product.price, product.currency) }}
                    </strong>
                  </template>
                  <template v-else>
                    <template v-if="product.price_prefix">
                      {{ $t('cs.front.at') }}
                    </template>
                    {{ $filters.priceFormat(product.price, product.currency) }}
                  </template>
                </div>
              </div>
              <div
                v-if="product.colors_count"
                class="in-colors"
              >
                <img src="../../../assets/img/colors.png" alt="">

                {{ $t('cs.front.in') }}
                {{ product.colors_count }}

                <template v-if="product.colors_count === 1">
                  {{ $t('cs.front.color') }}
                </template>
                <template v-else>
                  {{ $t('cs.front.colors') }}
                </template>

              </div>
            </router-link>
          </div>
          <nav>
            <br /><br />
            <laravel-vue-pagination
              :limit="3"
              :data="Object.assign({}, productsList)"
              @pagination-change-page="fetchProductsWrapper"
            />
          </nav>
        </main>
        <main
          v-if="!showLoader && categoryType === 'cs'"
          class="content home-page"
        >
          <template v-for="block in blocks" :key="block.id">
            <products-slider
              v-if="block.type === contentTypes.TYPE_SLIDER"
              :products="block.products"
              :header="block.name"
            />

            <banner-block
              v-if="block.type === contentTypes.TYPE_BANNER"
              :name="block.name"
              :text="block.text"
              :image="block.banner"
            />
          </template>
        </main>
      </div>
    </div>

    <sharing-box
      v-if="shop && selectedCategory && selectedCategory.name"
      :title="`${selectedCategory.name} | ${shop.name}`"
    />

  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import LaravelVuePagination from 'laravel-vue-pagination';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import SharingBox from '@/views/common/SharingBox.vue';
import BannerBlock from '../../common/content_blocks/BannerBlock.vue';
import ProductsSlider from '../../common/content_blocks/ProductsSlider.vue';
import * as contentTypes from '../../../consts/content_blocks';

export default {
  components: {
    BannerBlock,
    ProductsSlider,
    LaravelVuePagination,
    VueSlider,
    SharingBox,
  },
  data() {
    return {
      id: null,
      categoryType: null,
      filter: {
        q: null, gender: null, colorId: null, size_id: null, prices: [0, 250],
      },
      showFilters: false,
      filterApplied: false,
      priceSliderOptions: {
        lazy: true,
        dotSize: 24,
        width: 'auto',
        height: 4,
        contained: true,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 250,
        interval: 1,
        duration: 0.5,
      },
      genders: [
        { id: 'M', name: this.$t('cs.front.gender_name_m') },
        { id: 'W', name: this.$t('cs.front.gender_name_w') },
      ],
      colors: [
        {
          id: 1, css_class: '', css_style: 'background-color: #000000', name: this.$t('cs.front.colors.black'),
        }, // black
        {
          id: 2, css_class: '', css_style: 'background-color: #2A26C1', name: this.$t('cs.front.colors.blue'),
        }, // blue
        {
          id: 3, css_class: '', css_style: 'background-color: #6F3E13', name: this.$t('cs.front.colors.brown'),
        }, // brown
        {
          id: 4, css_class: '', css_style: 'background-color: #63C027', name: this.$t('cs.front.colors.green'),
        }, // green
        {
          id: 6, css_class: '', css_style: 'background-color: #8A8A8A', name: this.$t('cs.front.colors.grey'),
        }, // grey
        {
          id: 7, css_class: '', css_style: 'background-color: #8427C0', name: this.$t('cs.front.colors.purple'),
        }, // purple
        {
          id: 8, css_class: '', css_style: 'background-color: #DD2324', name: this.$t('cs.front.colors.red'),
        }, // red
        {
          id: 9, css_class: '', css_style: 'background-color: #FF8000', name: this.$t('cs.front.colors.orange'),
        }, // orange
        {
          id: 10, css_class: '', css_style: 'background-color: #FFFFFF; border: 1px solid #000;', name: this.$t('cs.front.colors.white'),
        }, // white
        {
          id: 12, css_class: '', css_style: 'background-color: #DFD828', name: this.$t('cs.front.colors.yellow'),
        }, // yellow
      ],
      contentTypes,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters({
      blocks: 'contentBlock/list',
      products: 'product/list',
      eoProducts: 'eoProduct/list',
      categories: 'category/list',
      eoCategories: 'eoCategory/list',
      shop: 'shop/selected',
      category: 'category/selected',
      eoCategory: 'eoCategory/selected',
      apiHost: 'apiHost',
      staticHost: 'staticHost',
      showLoader: 'showLoader',
    }),
    categoriesList() {
      if (this.categoryType === 'cs') {
        return this.categories;
      }

      return this.eoCategories;
    },
    productsList() {
      if (this.categoryType === 'cs') {
        return this.products;
      }

      return this.eoProducts;
    },
    selectedCategory() {
      if (this.categoryType === 'cs') {
        return this.category;
      }

      return this.eoCategory;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.categoryId': function (newId) {
      if (newId) {
        this.categoryType = 'cs';
        this.id = newId;
        this.fetchCategoryWrapper(this.id).then(() => {
        });
      }
    },
    // eslint-disable-next-line func-names
    '$route.params.eoCategoryId': function (newId) {
      if (newId) {
        this.categoryType = 'eo';
        this.id = parseInt(newId, 10);
        this.fetchCategoryWrapper(this.id).then(() => {

        });
      }
    },
    // eslint-disable-next-line func-names
    'filter.colorId': function () {
      this.applyFilter();
    },
    // eslint-disable-next-line func-names
    'filter.size_id': function () {
      this.applyFilter();
    },
    // eslint-disable-next-line func-names
    'filter.gender': function () {
      this.applyFilter();
    },
  },
  methods: {
    ...mapActions('contentBlock', {
      fetchBlocks: 'fetch',
    }),
    ...mapActions('product', {
      fetchProducts: 'fetch',
    }),
    ...mapActions('eoProduct', {
      fetchEOProducts: 'fetch',
    }),
    ...mapActions('category', {
      fetchCategories: 'fetch',
      fetchCategory: 'select',
    }),
    ...mapActions('eoCategory', {
      fetchEOCategories: 'fetch',
      fetchEOCategory: 'select',
    }),
    clearFilter() {
      this.filter = {
        gender: null, colorId: null, prices: [0, 250],
      };

      this.showFilters = false;
    },
    applyFilter() {
      this.showFilters = false;

      return this.fetchProductsWrapper();
    },
    fetchProductsWrapper(page = 1) {
      this.$store.commit('showLoader');

      if (this.categoryType === 'cs') {
        return this.fetchProducts({
          page,
          q: this.filter.q,
          category_id: this.id,
          gender: (this.filter.gender) ? this.filter.gender : null,
          color_id: (this.filter.colorId) ? this.filter.colorId : null,
          size_id: (this.filter.size_id) ? this.filter.size_id : null,
          price_from: this.filter.prices[0],
          price_to: this.filter.prices[1],
        }).finally(() => this.$store.commit('hideLoader'));
      }

      return this.fetchEOProducts({
        page,
        q: this.filter.q,
        category_id: this.id,
        gender: (this.filter.gender) ? this.filter.gender : null,
        color_id: (this.filter.colorId) ? this.filter.colorId : null,
        size_id: (this.filter.size_id) ? this.filter.size_id : null,
        price_from: this.filter.prices[0],
        price_to: this.filter.prices[1],
      }).finally(() => this.$store.commit('hideLoader'));
    },
    fetchCategoryWrapper() {
      if (this.categoryType === 'cs') {
        return this.fetchCategory(this.id).then(() => {
          this.id = this.category.id;
          this.fetchProductsWrapper();
          this.fetchBlocks({ category_id: this.id });

          if (this.category && this.shop && this.shop.club) {
            document.title = this.category.seo_title
              ?? `${this.shop.club.name} / ${this.shop.name} - ${this.category.name}`;
          }
        }).finally(() => {

        });
      }

      return this.fetchEOCategory(this.id).then(() => {
        this.fetchProductsWrapper();
      });
    },
    productUrl(product) {
      if (this.categoryType === 'cs') {
        return { name: 'product', params: { productId: product.id } };
      }

      return { name: 'eo-product', params: { eoProductId: product.id } };
    },
    setAdaptive() {
      // eslint-disable-next-line no-restricted-globals
      this.isMobile = (window.innerWidth < 992);
    },
  },
  mounted() {
    const route = useRoute();
    if (route.params.categoryId) {
      this.id = route.params.categoryId;
      this.categoryType = 'cs';
    }

    if (route.params.eoCategoryId) {
      this.id = parseInt(route.params.eoCategoryId, 10);
      this.categoryType = 'eo';
    }

    this.fetchCategoryWrapper(this.id);

    this.fetchEOCategories();

    this.setAdaptive();

    window.addEventListener('resize', () => {
      this.setAdaptive();
    });
  },
  beforeUnmount() {
    document.title = `${this.shop.club.name} / ${this.shop.name}`;
  },
};
</script>

<style>
  .vue-slider-process, .vue-slider-process:hover {
    background: #76b935;
  }
  .vue-slider:hover .vue-slider-dot-handle {
    background-color: green;
  }
</style>
