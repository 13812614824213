import axios from 'axios';

export default {
  fetch(context) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/cart/positions/`,
    ).then((response) => {
      context.commit('setCart', response.data);

      return response;
    });
  },
  addPositions(context, sizes) {
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/cart/positions/`,
      {
        sizes,
      },
    ).then((response) => {
      context.commit('setCart', response.data);

      return response;
    });
  },
  updatePosition(context, position) {
    if (position.quantity <= 0) {
      // eslint-disable-next-line no-param-reassign
      position.quantity = 1;
      return false;
    }
    return axios.put(
      `${context.rootState.apiHost}/club-shop/app/cart/positions/${position.id}`,
      {
        quantity: position.quantity,
      },
    ).finally((response) => {
      context.dispatch('fetch');

      return response;
    });
  },
  deletePosition(context, id) {
    return axios.delete(
      `${context.rootState.apiHost}/club-shop/app/cart/positions/${id}`,
    ).then((response) => {
      context.dispatch('fetch');

      return response;
    });
  },
  createPaymentTransaction(context, data) {
    if (data.fileToUpload) {
      const formData = new FormData();
      Object.keys(data).forEach((a) => a === 'fileToUpload'
        || (data[a] && formData.append(a, JSON.stringify(data[a]))));
      formData.append('file', data.fileToUpload);
      return axios.post(
        `${context.rootState.apiHost}/club-shop/app/cart/payment/`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      ).then((response) => response);
    }
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/cart/payment/`,
      data,
    ).then((response) => response);
  },
  paymentTransactionCallback(context, data) {
    return axios.put(`${context.rootState.apiHost}/club-shop/app/cart/payment/${data.transaction_id}/callback`, data).then((response) => response);
  },
};
