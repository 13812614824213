import axios from 'axios';

export default {
  fetch(context, filters = {}) {
    return axios.get(
      `${context.rootState.apiHost}/app/info-blocks/?target=c`,
      { params: filters },
    ).then((response) => {
      context.commit('setList', response.data.data);
    });
  },
};
