import axios from 'axios';

export default {
  select: (context, url) => {
    axios.defaults.headers.common['X-Shop-Id'] = url;

    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/shop/${url}`,
      {
        params: {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          referer: document.referrer,
        },
      },
    ).then((response) => {
      context.commit('shop/select', response.data, { root: true });

      return response;
    });
  },
};
